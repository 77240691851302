<template>
  <div class="project-body">
    <Navbar />
    <div class="project">
      <h1>Lettres de missions</h1>
      <p>
        Projet réalisé à l'aide de Vue.js (front), Laravel (back) ainsi que
        MongoDB (base de données).
      </p>
      <p>
        Dans quel cadre ? : bootcamp Alt
      </p>
    </div>
    <div class="container-project">
      <img
        class="first"
        src="@/assets/images/portfolio/lettresmissions/lettresmissions-1.png"
        data-aos="zoom-in"
        data-aos-duration="1000"
      />
      <p>
        Pour commencer, il a fallu créer des mockups pour soumettre des
        propositions au client (Cabinet d'avocats) afin qu'il puisse valider la
        direction à prendre
      </p>
    </div>
    <div class="container-project1">
      <p>
        L'utilisation de Vuetify nous a permis de créer des visuels à partir de
        wizards, de tableaux, etc... L'utilisation de Vuetify nous a permis de
        créer des visuels à partir de wizards, de tableaux, etc...
      </p>
      <img
        class="second"
        src="@/assets/images/portfolio/lettresmissions/lettresmissions-2.png"
        data-aos="zoom-in"
        data-aos-duration="1000"
      />
    </div>
    <p>
      <router-link
        class="btn btn-dark mt-2 float-lg-center mb-lg-5 btn-sm"
        :to="{ name: 'Portfolio' }"
      >
        <fontAwesomeIcon :icon="['fas', 'arrow-left']" class=" menuColor" />
        Retour vers le portfolio
      </router-link>
    </p>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Lettresmissions",
  components: { Navbar, Footer }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/projetView.scss";
</style>
